<template>
  <div class="home-training-section-left">
    <h2>
      <label class="block">
        <picture>
          <source
            media="(min-width: 767px)"
            :srcset="
              trainingSectionData &&
              trainingSectionData.logo &&
              trainingSectionData.logo.desktop
                ? trainingSectionData.logo.desktop
                : ''
            "
          />
          <img
            :src="
              trainingSectionData &&
              trainingSectionData.logo &&
              trainingSectionData.logo.mobile
                ? trainingSectionData.logo.mobile
                : ''
            "
            :alt="
              trainingSectionData &&
              trainingSectionData.logo &&
              trainingSectionData.logo.alt
                ? trainingSectionData.logo.alt
                : ''
            "
            @error="handleImageError"
          />
        </picture>
      </label>
      {{
        $t(
          trainingSectionData && trainingSectionData.title
            ? trainingSectionData.title
            : 'The Colfert training'
        )
      }}
    </h2>
    <p>
      {{
        $t(
          trainingSectionData && trainingSectionData.details
            ? trainingSectionData.details
            : ''
        )
      }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HomeTrainingLeftData',
  props: {
    trainingSectionData: {
      type: [Array, Object],
      default: () => [] || {},
    },
  },
  setup() {},
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
});
</script>
