var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-training-section-left"},[_c('h2',[_c('label',{staticClass:"block"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 767px)","srcset":_vm.trainingSectionData &&
            _vm.trainingSectionData.logo &&
            _vm.trainingSectionData.logo.desktop
              ? _vm.trainingSectionData.logo.desktop
              : ''}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.trainingSectionData &&
            _vm.trainingSectionData.logo &&
            _vm.trainingSectionData.logo.mobile
              ? _vm.trainingSectionData.logo.mobile
              : '',"alt":_vm.trainingSectionData &&
            _vm.trainingSectionData.logo &&
            _vm.trainingSectionData.logo.alt
              ? _vm.trainingSectionData.logo.alt
              : ''},on:{"error":_vm.handleImageError}})])]),_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.trainingSectionData && _vm.trainingSectionData.title
          ? _vm.trainingSectionData.title
          : 'The Colfert training'
      ))+"\n  ")]),_vm._v(" "),_c('p',[_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.trainingSectionData && _vm.trainingSectionData.details
          ? _vm.trainingSectionData.details
          : ''
      ))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }