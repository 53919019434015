<template>
  <nuxt-link
    :to="localePath(item && item.link && item.link.url ? item.link.url : '/')"
  >
    <picture class="image w-full block">
      <source
        media="(min-width: 767px)"
        :srcset="
          item && item.image && item.image.desktop
            ? item.image.desktop
            : '/homepage/home-training.jpg'
        "
      />
      <img
        :src="
          item && item.image && item.image.mobile
            ? item.image.mobile
            : '/homepage/home-training.jpg'
        "
        :alt="item && item.image && item.image.alt ? item.image.alt : ''"
        @error="handleImageError"
        width="300"
        :class="handleImageError ? 'placeholder' : ''"
        height="200"
        class="w-full h-auto inset absolute"
      />
    </picture>
    <h3>
      {{ $t(item && item.title ? item.title : '') }}
    </h3>
    <p>
      {{ $t(item && item.subtitle ? item.subtitle : '') }}
    </p>
  </nuxt-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HomeTrainingLeftData',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
  methods: {
    handleImageError(event) {
      event.target.src = '/icons/logo.svg';
    },
  },
});
</script>
