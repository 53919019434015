<template>
  <section class="full-width">
    <div class="divider-design" />
    <div class="container home-training-container">
      <div class="home-training-section-row flex">
        <HomeTrainingLeftData :training-section-data="trainingData" />
        <div class="home-training-right relative">
          <div class="swiper-slider-wrap">
            <div
              class="swiper"
              v-swiper="SWIPER_OPTIONS"
              instance-name="home-training-section"
            >
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide relative"
                  v-for="(item, index) in trainingData.items.length > 0
                    ? trainingData.items
                    : []"
                  :key="`training-${index}`"
                >
                  <HomeTrainingRightData :item="item" />
                </div>
              </div>
            </div>
          </div>
          <div class="s-arrow">
            <div
              id="home-training-section-button-prev"
              class="swiper-button-prev button-prev"
            >
              <SliderLeftArrow />
            </div>
            <div
              id="home-training-section-button-next"
              class="swiper-button-next button-next"
            >
              <SliderRightArrow />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SwiperCore, { Navigation } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import { SliderLeftArrow, SliderRightArrow } from '~/components/General/Icons';
import HomeTrainingLeftData from '~/components/Home/Training/HomeTrainingLeftData.vue';
import HomeTrainingRightData from '~/components/Home/Training/HomeTrainingRightData.vue';

SwiperCore.use([Navigation]);

export default defineComponent({
  name: 'HomeTraining',
  components: {
    SliderLeftArrow,
    SliderRightArrow,
    HomeTrainingLeftData,
    HomeTrainingRightData,
  },
  props: {
    trainingData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const SWIPER_OPTIONS = {
      loop: false,
      observer: true,
      observeParents: true,
      slidesPerView: 3,
      spaceBetween: 24,
      resizeObserver: true,
      navigation: {
        nextEl: `#home-training-section-button-next`,
        prevEl: `#home-training-section-button-prev`,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.4,
          spaceBetween: 60,
          allowTouchMove: true,
        },
        767: {
          slidesPerView: 1.7,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 2.2,
          spaceBetween: 24,
        },
        1900: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
    };

    return {
      SWIPER_OPTIONS,
    };
  },
  directives: {
    swiper: directive,
  },
});
</script>

<style lang="scss">
.home-training-container {
  background-color: var(--c-grey);
}
.home-training-section {
  width: 100%;
  position: relative;
  background: var(--c-yellow);
  .divider-design {
    background-color: var(--c-grey);
    &::after {
      background: var(--c-dark-grey);
    }
  }
  .home-training-section-row {
    padding: 5.25rem 0;
    @include to-tablet-max {
      padding: 1.875rem 0 3.375rem 0;
    }
    @include to-mobile-max {
      display: var(--block);
    }
    .home-training-section-left {
      min-width: 19.25rem;
      max-width: 38%;
      padding-right: 1.5rem;
      width: var(--w-full);
      @include to-mobile-max {
        min-width: 100%;
        max-width: 100%;
        padding: 0 0 1.875rem 0;
      }
      h2 {
        @include font-italic;
        margin-bottom: 1.125rem;
        font-weight: normal;
        @include to-tablet-max {
          font-size: var(--font-size-24);
          line-height: var(--line-height-28);
        }
        label {
          margin-bottom: 1.5rem;
          @include to-tablet-max {
            margin-bottom: 0.5rem;
          }
          svg {
            max-width: 11.25rem;
            @include to-tablet-max {
              max-width: 6.25rem;
              height: var(--h-auto);
            }
          }
        }
      }
      p {
        margin-bottom: 0;
        max-width: 32.188rem;
        @include to-tablet-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
          max-width: 100%;
        }
      }
    }
    .home-training-right {
      width: 62%;
      overflow: hidden;
      &:hover {
        .s-arrow {
          display: var(--block);
        }
      }
      @include to-mobile-max {
        width: 100%;
      }
    }
  }
  .s-arrow {
    justify-content: space-between;
    display: var(--hidden);
    @include for-mobile {
      display: block;
    }
    .swiper-button-next,
    .swiper-button-prev {
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 28%;
      transform: translatey(-50%);
      @include for-mobile {
        display: block;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
      svg {
        width: 1.25rem;
        @include for-mobile {
          height: 35px;
          width: auto;
        }
      }
    }
    .button-prev {
      left: 1.875rem;
      @include for-mobile {
        left: 0.625rem;
      }
    }
    .button-next {
      right: 1.875rem;
      @include for-mobile {
        right: 0.625rem;
      }
    }
  }
  .swiper-slide {
    position: var(--relative);
    a {
      color: var(--c-white);
      display: var(--flex);
      justify-content: center;
      align-items: center;
      flex-direction: var(--flex-col);
      text-align: center;
      .image {
        line-height: 0;
        position: var(--relative);
        padding-bottom: 59%;
        overflow: hidden;
        img {
          line-height: 0;
          object-fit: cover;
          transition: transform 0.5s; /* Animation */
        }
      }
      &:hover {
        label {
          background: var(--c-dark-grey);
          color: var(--c-white);
        }
        .image {
          img {
            transform: scale(1.02);
          }
        }
      }
      h3 {
        font-size: var(--font-size-20);
        line-height: var(--line-height-24);
        padding: 1.5rem 0 0.375rem 0;
        margin: 0;
        color: var(--c-dark-grey);
        @include to-mobile-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
          padding: 1rem 0 0.875rem 0;
        }
      }
      p {
        margin: 0;
        color: var(--c-dark-grey);
        @include to-mobile-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
    }
    &::after {
      @include to-mobile-max {
        content: '';
        border-right: solid 1px var(--c-dark-grey);
        height: var(--h-full);
        position: var(--absolute);
        right: -1.875rem;
        top: var(--top-0);
      }
    }
  }
  .swiper:not(.swiper-container-initialized) {
    .swiper-wrapper {
      display: var(--flex);
      flex-wrap: nowrap;
      overflow: hidden;
      .swiper-slide {
        flex: 0 0 33.3333333333%;
        width: var(--w-full);
        margin-right: 1.5rem;
        @include to-tablet-1200 {
          margin-right: 1.5rem;
          flex: 0 0 44%;
        }
        @include to-mobile-max {
          margin-right: 3.75rem;
          flex: 0 0 62%;
        }
      }
    }
  }
}
</style>
